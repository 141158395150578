import { useQuery } from '@tanstack/react-query'
import { getTroopsByWallet } from '../lib/indexer/getTroopsByWallet'
import { zeroAddress } from 'viem'
import { Troop } from '../types'

function useGetUserTroops(userAddress?: `0x${string}`) {
    const {
        data: userTroopMemberships,
        isError: isErrorTroopMemberships,
        isLoading: isLoadingTroopMemberships,
        isSuccess: isSuccessTroopMemberships,
    } = useQuery(
        ['getTroopMembers', { wallet: userAddress?.toLowerCase() as `0x${string}` }] as const,
        ({ queryKey }) => {
            const [_key, { wallet }] = queryKey
            return getTroopsByWallet({ wallet })
        },
        {
            enabled: Boolean(userAddress),
        }
    )

    const memberships = userTroopMemberships?.data.wallet?.memberships || []

    // Filter memberships in case of indexer/db error
    const troopsMemberships = memberships
        ?.filter((membershipNft) => Boolean(membershipNft.membership_nft?.troop.id))
        .map((membershipNft) => {
            return {
                full_name: membershipNft.membership_nft?.troop.full_name ?? 'Not indexed',
                id: membershipNft.membership_nft?.troop.id ?? zeroAddress,
                slug: membershipNft.membership_nft?.troop.slug ?? 'Not indexed',
                safe_address: membershipNft.membership_nft?.troop.safe_address ?? zeroAddress,
                module_address: membershipNft.membership_nft?.troop.module_address ?? zeroAddress,
                created_at: membershipNft.membership_nft?.troop.created_at ?? '',
                created_by: membershipNft.membership_nft?.troop.created_by ?? zeroAddress,
                membership_nft: membershipNft.membership_nft?.troop.membership_nft ?? zeroAddress,
            } as Troop
        })

    return {
        isErrorTroopMemberships,
        isLoadingTroopMemberships,
        isSuccessTroopMemberships,
        troopsMemberships,
    }
}

export default useGetUserTroops
