export const TROOPS_PALETTE_OBJ = {
    blue: '#0000FF',
    cyan: '#00FFFF',
    green: '#00FF00',
    orange: '#FF8500',
    rose: '#FF00FF',
    yellow: '#FCF100',
    purple: '#6400BC',
}

export const TROOPS_PALETTE_ARR = [
    '#0000FF',
    '#00FFFF',
    '#00FF00',
    '#FF8500',
    '#FF00FF',
    '#FCF100',
    '#6400BC',
]

export const TROOPS_GRADIENT_OBJ = {
    gradientSunmeadow: ['#FCF100', '#00FF00'],
    gradientSunocean: ['#6400BC', '#00FFFF'],
    gradientSunsea: ['#FF00FF', '#0000FF'],
    gradientLinear: ['#FF8500', '#FF00FF'],
    gradientRoseCyan: ['#FF00FF', '#00FFFF'],
    gradientGreenRose: ['#00FF00', '#FF00FF'],
    gradientCyanYellow: ['#00FFFF', '#FCF100'],
    gradientGreenPurple: ['#00FF00', '#6400BC'],
    gradientYellowRose: ['#FCF100', '#FF00FF'],
    gradientOrangeBlue: ['#FF8500', '#0000FF'],
}

export const TROOPS_GRADIENT_ARR = [
    ['#FCF100', '#00FF00'],
    ['#6400BC', '#00FFFF'],
    ['#FF00FF', '#0000FF'],
    ['#FF8500', '#FF00FF'],
    ['#FF00FF', '#00FFFF'],
    ['#00FF00', '#FF00FF'],
    ['#00FFFF', '#FCF100'],
    ['#00FF00', '#6400BC'],
    ['#FCF100', '#FF00FF'],
    ['#FF8500', '#0000FF'],
]

export const GRADIENT_DIR_ARR = ['to-t', 'to-tr', 'to-r', 'to-br', 'to-b', 'to-bl', 'to-l', 'to-tl']
