import { Troop } from '@/src/types'
import { fetchIndexerData } from '@/src/utils/graphqlFetcher'
import { Address } from 'viem'

const getTroopsByWalletGql = `
query GetTroopsByWallet($wallet: String!) {
    wallet(id: $wallet) {
        id
        memberships(orderBy: "created_at", orderDirection:"desc") {
            membership_nft {
                id
                troop {
                    slug
                    full_name
                    safe_address
                    module_address
                    id
                    created_by
                    created_at
                }
            }
        }
    }
}`

interface GetUserTroopsRequest {
    wallet: Address
}

interface TroopsResponse {
    data: {
        wallet: {
            memberships: {
                membership_nft: {
                    troop: Troop & {
                        membership_nft: `0x${string}`
                    }
                }
            }[]
        }
    }
}

export function getTroopsByWallet(request: GetUserTroopsRequest) {
    return fetchIndexerData<TroopsResponse, GetUserTroopsRequest>(getTroopsByWalletGql, request)
}
