const graphqlEndpoint = process.env.NEXT_PUBLIC_SCROLL_INDEXER ?? ''

export async function fetchIndexerData<TData, TVariables>(
    query: string,
    variables?: TVariables
): Promise<TData> {
    const response = await fetch(graphqlEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables,
        }),
    })

    if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText)
    }

    return response.json() as Promise<TData>
}
